.wrapper.search-job {
  .search-job-form {
    .search-job-form-item {
      //   width: 100%;
      @include set_font(16px, 200, 1);
      @include mq_min_750 {
        @include set_font(23px, 200, 1);
      }
      .search-job-form-item-input {
        @include set_font(inherit, inherit, inherit);
      }
      .search-job-form-button {
        max-width: fit-content;
        height: auto;
        display: block;
        margin: 20px auto 0;
        @include set_font(inherit, inherit, inherit);
      }
    }
  }
  .search-job-result {
    margin-top: 20px;
    .search-job-result-info {
      padding: 20px;
      border-radius: 6px;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      .search-job-result-info-item {
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
        .search-job-result-info-item-title {
          margin-bottom: 10px;
          font-weight: 300;
        }
        .search-job-result-info-detail {
          width: 100%;
          .search-job-result-info-key {
            padding: 15px 10px 15px 0;
          }
        }
      }
    }
    .search-job-result-task {
      margin-top: 30px;
      padding: 20px;
      border-radius: 6px;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      .search-job-result-task-title {
        margin-bottom: 10px;
        font-weight: 300;
      }
      .search-job-result-task-table {
        .search-job-result-task-table-row {
          .search-job-result-task-table-cell {
            .bold {
              font-weight: 300;
            }
          }
        }
      }
    }
  }
}
