.wrapper.booking {
  background-color: #d7dbe2;
  .booking-form {
    .booking-form-wrapper {
      @include flex(space-between, normal);
      flex-wrap: wrap;
      gap: convertToVw(20px);
      @include mq_min_750 {
        gap: 20px;
      }
      &.only-num-day {
        .booking-form-item {
          &:nth-child(3) {
            width: 100%;
          }
          &:nth-child(4) {
            width: 100%;
          }
          &:nth-child(5) {
            width: calc(70% - convertToVw(10px));
            height: auto;
            @include mq_min_750 {
              width: calc(70% - 10px);
            }
          }
          &:nth-child(6) {
            width: calc(30% - convertToVw(10px));
            @include mq_min_750 {
              width: calc(30% - 10px);
            }
          }
          //   .ant-form-item-row {
          //     height: 100%;
          //     .ant-form-item-control {
          //       height: 100%;
          //       .ant-form-item-control-input {
          //         height: 100%;
          //         align-items: initial;
          //       }
          //     }
          //   }
        }
      }
      .booking-form-item {
        width: calc(50% - convertToVw(10px));
        @include mq_min_750 {
          width: calc(50% - 10px);
        }
        .ant-picker-input {
          input {
            @include set_font(16px, 200, 1);
            @include mq_min_750 {
              @include set_font(23px, 200, 1);
            }
            &::placeholder {
              @include set_font(16px, 200, 1);
              color: #757575;
              @include mq_min_750 {
                @include set_font(23px, 200, 1);
              }
            }
          }
        }
        &.has-icon {
          position: relative;
          .booking-form-icon {
            width: convertToVw(35px);
            height: convertToVw(35px);
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: convertToVw(20px);
            filter: brightness(0) saturate(100%) invert(65%) sepia(36%) saturate(923%) hue-rotate(171deg)
              brightness(100%) contrast(98%);
            @include mq_min_750 {
              width: 35px;
              height: 35px;
              right: 20px;
            }
          }
        }
        &:nth-child(5) {
          width: 100%;
          //   height: convertToVw(200px);
          //   @include mq_min_750 {
          //     height: 200px;
          //   }
        }
        &:nth-child(6) {
          width: calc(70% - convertToVw(10px));
          @include mq_min_750 {
            width: calc(70% - 10px);
          }
        }
        &:nth-child(7) {
          width: calc(30% - convertToVw(10px));
          @include mq_min_750 {
            width: calc(30% - 10px);
          }
        }
        .num-day {
          //   padding: 18px 25px !important;
          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          &[type="number"] {
            -moz-appearance: textfield;
            appearance: textfield;
          }
          @include set_font(convertToVw(25px), 400, 1);
          @include mq_min_750 {
            @include set_font(25px, 400, 1);
          }
          .ant-input-number-handler-wrap {
            display: none;
          }
          input {
            padding: 0;
            @include set_font(convertToVw(25px), 400, 1);
            @include mq_min_750 {
              @include set_font(25px, 400, 1);
            }
          }
        }
        .booking-form-input {
          width: 100%;
          padding: convertToVw(15px);
          border: 1px solid #787d88;
          border-radius: 4px;
          @include set_font(convertToVw(25px), 400, 1);
          @include mq_min_750 {
            padding: 15px;
            @include set_font(23px, 400, 1);
          }
          &.textarea {
            height: 100%;
            resize: none;
          }
        }
        .booking-form-timepicker {
          //   @media screen and (max-width: 992px) {
          .ant-picker-panel-container {
            width: 100%;
            @include mq_min_750 {
              max-width: 50%;
            }
            .ant-picker-panel-layout {
              width: 100%;
              display: block;
              .ant-picker-panel {
                width: 100%;
                .ant-picker-time-panel {
                  .ant-picker-time-panel-column {
                    width: 50%;
                    overflow-y: auto;
                    .ant-picker-time-panel-cell {
                      &:first-child {
                        .ant-picker-time-panel-cell-inner {
                          margin-top: 0;
                        }
                      }
                      &:last-child {
                        .ant-picker-time-panel-cell-inner {
                          margin-bottom: 0;
                        }
                      }
                      .ant-picker-time-panel-cell-inner {
                        width: auto;
                        height: auto;
                        margin: 5px auto;
                        padding: 5px 0;
                        text-align: center;
                      }
                    }
                  }
                }
              }
            }
          }
          //   }
        }
        // .ant-form-item-row {
        //   height: 100%;
        //   * {
        //     height: 100%;
        //   }
        // }
        .booking-coupon-button {
          width: 100%;
          height: 100%;
          padding: convertToVw(15px);
          //   @include set_font(convertToVw(25px), 400, 1);
          @include mq_min_750 {
            padding: 15px;
            // @include set_font(25px, 400, 1);
          }
          &:disabled {
            background: #d7dbe2;
            color: #787d88;
            cursor: not-allowed;
          }
        }
        .ant-form-item-explain-error {
          margin: convertToVw(10px) 0 0;
          @include set_font(14px, 200, 1);
          @include mq_min_750 {
            margin: 10px 0 0;
            @include set_font(20px, 200, 1);
          }
        }
      }
    }
    .select-user {
      @include flex(flex-start, center);
      margin-top: convertToVw(40px);
      padding: convertToVw(15px);
      border: 1px solid #787d88;
      border-radius: 5px;
      background-color: #fff;
      cursor: pointer;
      @include mq_min_750 {
        margin-top: 40px;
        padding: 15px;
      }
      .select-user-icon {
        width: convertToVw(25px);
        height: convertToVw(25px);
        margin-right: convertToVw(20px);
        @include mq_min_750 {
          width: 23px;
          height: 23px;
          margin-right: 20px;
        }
      }
      .select-user-text {
        // @include set_font(convertToVw(30px), 400, 1);
        color: #1a75bb;
        // @include mq_min_750 {
        //   @include set_font(25px, 400, 1);
        // }
      }
    }
    .amount {
      margin-top: convertToVw(40px);
      @include mq_min_750 {
        margin-top: 40px;
      }
      .amount-item {
        @include flex(space-between, center);
        margin-bottom: convertToVw(20px);
        // @include set_font(convertToVw(30px), 400, 1);
        @include mq_min_750 {
          margin-bottom: 20px;
          //   @include set_font(30px, 400, 1);
        }
        &:nth-child(3) {
          .amount-text {
            color: #1a75bb;
          }
          .amount-value {
            // @include set_font(convertToVw(40px), 400, 1);
            font-weight: 300;
            color: #d42727;
            // @include mq_min_750 {
            //   @include set_font(40px, 400, 1);
            // }
          }
        }
      }
    }
    .booking-button-wrapper {
      left: 50%;
      transform: translateX(-50%);
      .ant-btn {
        height: auto;
        &:hover {
          background: linear-gradient(to right, #4f5bb4, #359dd4) border-box;
          background-repeat: no-repeat;
        }
      }
    }
  }
  .booking {
    width: 100%;
    position: fixed;
    bottom: 0;
    min-height: convertToVw(75px);
    padding: convertToVw(30px);
    border-top: 1px solid #fff;
    background-color: #d7dbe2;
    @include mq_min_750 {
      max-width: 1000px;
      min-height: 75px;
      padding: 30px;
    }
    .booking-button {
      display: block;
      text-align: center;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
  }
}
.wrapper.booking-type {
  .booking-type-item {
    .booking-type-group {
      width: 100%;
      .booking-type-checkbox {
        width: 100%;
        margin-bottom: convertToVw(30px);
        margin-right: 0;
        padding: convertToVw(20px);
        border-radius: 6px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        background-color: #fff;
        transition: all 0.3s ease;
        @include mq_min_750 {
          margin-bottom: 30px;
          padding: 20px;
        }
        &.ant-radio-wrapper-checked {
          background-color: #1677ff;
          color: #fff;
          .ant-radio-inner {
            background-color: #fff;
            &:after {
              background-color: #1677ff;
            }
          }
        }
        span:not(.ant-radio) {
          @include set_font(16px, 200, 1.2);
          @include mq_min_750 {
            @include set_font(23px, 200, 1);
          }
        }
      }
    }
  }
  .booking-button-wrapper {
    left: 50%;
    transform: translateX(-50%);
  }
}
.wrapper.booking-map {
  .booking-map {
    .img-container {
      width: convertToVw(30px);
      height: convertToVw(30px);
      @include mq_min_750 {
        width: 30px;
        height: 30px;
      }
    }
    .booking-map-list {
      .ant-input::placeholder {
        @include set_font(convertToVw(25px), 400, 1);
        @include mq_min_750 {
          @include set_font(25px, 400, 1);
        }
      }
      .booking-map-item {
        display: block;
        // @include flex(space-between, center);
        margin-bottom: 30px;
        // height: 45px;
        &.booking-map-text {
          padding: 0 11px;
          border: 1px solid #d9d9d9;
          border-radius: 6px;
          line-height: 1.2;
          background: #ffffff;
        }
        &.booking-map-input {
          @include set_font(16px, 200, 1);
          @include mq_min_750 {
            @include set_font(23px, 200, 1);
          }
          &::placeholder {
            @include set_font(16px, 200, 1);
            @include mq_min_750 {
              @include set_font(23px, 200, 1);
            }
          }
        }
        .ant-select-selector {
          @include set_font(16px, 200, 1);
          @include mq_min_750 {
            @include set_font(25px, 200, 1);
          }
          .ant-select-selection-search {
            .ant-select-selection-search-input {
              @include set_font(16px, 200, 1);
              @include mq_min_750 {
                @include set_font(25px, 200, 1);
              }
            }
          }
        }
        .booking-map-item-text {
          margin-right: convertToVw(20px);
          @include set_font(convertToVw(35px), 400, 1.1);
          @include truncate_text(1);
          @include mq_min_750 {
            margin-right: 20px;
            @include set_font(35px, 400, 1.1);
          }
        }
        .booking-map-item-icon {
          width: convertToVw(50px);
          height: convertToVw(50px);
          @include mq_min_750 {
            width: 50px;
            height: 50px;
          }
          img {
            object-fit: contain;
          }
        }
      }
    }
    .box-confirm {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: convertToVw(30px);
      @include mq_min_750 {
        margin-top: 30px;
      }
      .confirm-btn {
        width: 60%;
        margin: 20px auto 0 !important;
        display: block;
        height: auto;
        padding: convertToVw(25px) convertToVw(30px);
        text-align: center;
        // @include set_font(convertToVw(23px), 500, 1);
        @include mq_min_750 {
          padding: 25px 30px;
          //   @include set_font(23px, 500, 1);
        }
      }
    }
  }
}

.booking-title {
  margin-bottom: convertToVw(30px);
  font-weight: 300;
  text-align: center;
  @include mq_min_750 {
    margin-bottom: 30px;
  }
}
